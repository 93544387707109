// import WhyGetInvolved from '../../components/BestSchoolsAwards/WhyGetInvolved/WhyGetInvolved'
// import Categories from '../../components/BestSchoolsAwards/Categories/Categories'
import BSAContent from '../../components/BestSchoolsAwards/Content/Content'
import BSAFooter from '../../components/BestSchoolsAwards/Footer/Footer'
import TideHeaderLogo from '../../components/BestSchoolsAwards/HeaderLogo/TideHeaderLogo'
import Nav from '../../components/BestSchoolsAwards/Nav/Nav'
import React from 'react'
import { Helmet } from 'react-helmet'
// import * as ContentStyles from '../../components/BestSchoolsAwards/Content/Content.module.scss'
// import TestimonialSlider from '../../components/BestSchoolsAwards/TestimonialSlider/TestimonialSlider'

const WIBTerms = () => {
  return (
    <>
      <Helmet>
        <title>She Means Business | Muddy Stilettos</title>
      </Helmet>
      <Nav site="tide" theme="dark" />
      <TideHeaderLogo />
      <BSAContent>
        <h1>Terms &amp; Conditions</h1>
        <ul>
          <li>Entrants must be aged 18+ and a resident in the UK.</li>
          <li>
            Entrants must be women owning or co-owning and managing a business
            as explained below.
          </li>
          <li>
            Entrants must open a Tide Business Account with Tide Platform
            Limited (“Tide”).{' '}
            <a
              href="https://www.tide.co/support/joining/bank-account/who-can-open-a-tide-account/"
              target="_blank"
              rel="noreferrer"
            >
              Please see here for details of Tide’s Business Account eligibility
              criteria.
            </a>{' '}
            The opening of your Tide Business Account should be initiated on the
            following page{` `}
            <a href={`https://tide.co/offers/muddystilettos-shemeansbusiness/`}>
              tide.co/offers/muddystilettos-shemeansbusiness/
            </a>{' '}
            or if initiated through any of the other Tide channels, make sure
            you submit promotional code SHEMEANSBUSINESS upon submitting your
            account opening application. Once your account is opened Tide will
            send you a confirmation email. You must click on the email and
            follow the link to return to Muddy Stilettos’ website in order to
            complete your entry.
          </li>
          <li>
            Entrants must not already have an existing Tide Business Account.
            All participants acknowledge and agree that they are subject to the
            full Tide Terms governing their use of Tide Platform, which are
            <a
              href="https://static.tide.co/terms/CTBB/tide-terms.pdf"
              target="_BLANK"
              rel="noreferrer"
            >
              available here
            </a>{' '}
            (“Tide Platform Terms and Conditions”). The Tide Platform Terms and
            Conditions are subject to change from time to time and will include
            the requirement to go through Tide’s client verification and
            on-boarding checks. All personal data provided to Tide as part of
            its client verification and on-boarding checks will be processed by
            Tide in accordance with Tide’s{' '}
            <a href="https://www.tide.co/privacy/">Privacy Policy</a>. Tide
            shall not be required to provide any reason or be held responsible
            that any applicant has not been approved for a Tide Business Account
            by the closing date (2pm on 9 November 2023). 
          </li>
          <li>
            The closing date is 2pm, Thursday, 9th November to get your entries
            into the She Means Business Award. All applications uploaded by 2pm
            will be counted and then verified in the following 48 hours by Tide
            Bank. Successful applicants will receive confirmation emails to
            confirm their entry and bank application has been successful.
            Applicants must click the FINAL SUBMIT BUTTON within the email by{' '}
            <strong>12 noon, 13 November 2023</strong>. Any applications
            submitted after this time will not be accepted. Muddy cannot accept
            responsibility if their Tide Business Account application is not
            accepted following your She Means Business submission entry.{' '}
          </li>
          <li>
            Entrants can be an owner or co-owner of a business. If a co-owner,
            the woman must be managing the business entered into the
            competition.
          </li>
          <li>
            If the woman is a co-owner with men only, she needs to own a minimum
            of 50% of the business. If a co-owner with other women, there is no
            minimum requirement.
          </li>
          <li>Muddy Stilettos shall verify the eligibility of entrants. </li>
          <li>
            Entries will be reviewed by an expert judging panel from Muddy
            Stilettos that will choose three finalists. If you’re one of the
            three finalists, you’ll need to prepare a three-minute informal
            pitch to be presented to a small judging panel between 1-5pm, 06
            December at Thames Lido, Reading, Berks based on which one winner
            will be selected.
          </li>
          <li>
            If you are one of the three finalists and are an existing business,
            you will be asked to provide a 2022-23 Financial statement to prove
            you were in net profit for the last financial year. New business
            start-ups who have not yet traded for 1 year will need to show a
            bank statement to prove they are able to sustain their business
            going forward for 12 months in 2024.
          </li>
          <li>
            The judges’ decision will be final and no correspondence will be
            entered into before or after the judging. All decisions regarding
            the judging process are made by Muddy Stilettos&apos; judging panel.
          </li>
          <li>
            To be eligible entrants must make their competition submissions via
            the Muddy Stilettos SHE MEANS BUSINESS Award website and sign up for
            a Tide Business Account between 28 September and 9 November 2023.
            Three finalists will be notified on 29 November 2023.
          </li>
          <li>
            Competition submissions can only be made via the Muddy Stilettos
            competition website. The information provided will be disclosed only
            to the judges.
          </li>
          <li>
            Entry forms must be fully completed and uploaded onto the Muddy
            Stilettos competition website with any images and supporting
            documents.
          </li>
          <li>No purchase is required when entering this competition.</li>
          <li>Only one entry per person will be accepted.</li>
          <li>Only one winner will be selected to receive the prize. </li>
          <li>
            The winner will be announced on 12 December 2023. The £10,000 will
            be transferred to the winner by Muddy Stilettos on 30 December 2023.
          </li>
          <li>
            By participating in this competition, entrants agree to abide by
            these terms and conditions and the decisions of the competition
            administrators, which are final and binding in all respects.
          </li>
          <li>
            By participating in this competition, entrants agree that if they
            are the selected winner, information about them and their business
            will be displayed on Muddy Stilettos’ website and channels, in the
            local press and also across some of Tide’s channels for the purposes
            of featuring the winner in the competition.
          </li>
        </ul>
      </BSAContent>
      {/* <WhyGetInvolved /> */}
      <BSAFooter />
    </>
  )
}

export default WIBTerms
